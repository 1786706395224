import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { create } from "ipfs-http-client";

export const StyledButton = styled.button`
  padding: 20px;
  font-family: gopher;
  font-size: 17px;
  letter-spacing: normal;
  font-weight: 400;
  color: #e8ed5a;
  border-color: #e8ed5a;
  background: transparent;
  border-radius: 300px;
  cursor: pointer;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Get ready to adopt your cats!");
  const [claimingNFT, setClaimingNFT] = useState(false);

  const  claimNFTs = (_amount) => {
    setClaimingNFT(true);
    blockchain.smartContract.methods.mint(_amount).send({
      from: blockchain.account,
      value: blockchain.web3.utils.toWei((0.01 * _amount).toString(), "ether"),
    }).once("error", (err) => {
      console.log(err);
      setFeedback("Please check your transaction and refresh if you are having a connection issue.");
      setClaimingNFT(false);
    }).then((receipt) => {
      setFeedback("Success");
      setClaimingNFT(false);
    });
  };

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, dispatch]);

  return (
    <s.Screen>
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <s.Container flex={1} ai={"center"} jc={"top"} >
          <s.TextTitle  style={{ textAlign: "center" }}>Welcome to the Caturday Tales Mint!</s.TextTitle>
          <s.SpacerSmall />
          <s.TextParagraph style={{ textAlign: "center"}}>To mint a cat - connect your wallet.</s.TextParagraph>
          <s.SpacerSmall />
          <s.SpacerSmall />
          <StyledButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
            }}
          >
            CONNECT WALLET
          </StyledButton>
          <s.SpacerSmall />
          {blockchain.errorMsg !== "" ? (
            <s.TextDescription>{blockchain.errorMsg}</s.TextDescription>
          ) : null}
        </s.Container>
      ) : (
        <s.Container 
        flex={1} 
        ai={"center"} 
        jc={"top"}
         // EDIT THE STYLE OF THE MINTING PAGE
        >
          <s.TextTitle style={{ textAlign: "center" }}>
          How Many Cats? 
          </s.TextTitle>
          <s.SpacerXSmall />
          <s.TextParagraph style={{ textAlign: "center"}}>{feedback}</s.TextParagraph>
          <s.SpacerLarge /><s.SpacerLarge />
          <s.TextParagraph style={{ textAlign: "center"}}>Current Price: 0.01E (+ Gas)</s.TextParagraph>
          <s.SpacerLarge /><s.SpacerLarge />
          <StyledButton
          disabled={claimingNFT  ?  1 : 0}
            onClick={(e) => {
              e.preventDefault();
              claimNFTs(1);
            }}
          >
            {claimingNFT ? "Busy Minting" : "Adopt One Cat:  Mint 1"}
          </StyledButton> <s.SpacerXSmall />

          <s.SpacerSmall />
          <StyledButton
          disabled={claimingNFT  ?  1 : 0}
            onClick={(e) => {
              e.preventDefault();
              claimNFTs(2);
            }}
          >
            {claimingNFT ? "Busy Minting" : "Adopt Two Cats:  Mint 2"}
          </StyledButton>          <s.SpacerXSmall />

          <s.SpacerSmall />
          <StyledButton
          disabled={claimingNFT  ?  1 : 0}
            onClick={(e) => {
              e.preventDefault();
              claimNFTs(3);
            }}
          >
            {claimingNFT ? "Busy Minting" : "Adopt Three Cats:  Mint 3"}
          </StyledButton> <s.SpacerXSmall />

          <s.SpacerSmall />
          
          <StyledButton
          disabled={claimingNFT  ?  1 : 0}
            onClick={(e) => {
              e.preventDefault();
              claimNFTs(4);
            }}
          >
            {claimingNFT ? "Busy Minting" : "Adopt a Small Litter:  Mint 4"}
          </StyledButton> <s.SpacerXSmall />

          <s.SpacerSmall />
          
          <StyledButton
          disabled={claimingNFT  ?  1 : 0}
            onClick={(e) => {
              e.preventDefault();
              claimNFTs(5);
            }}
          >
            {claimingNFT ? "Busy Minting" : "Adopt a Large Litter: Mint 5"}
          </StyledButton>
          <s.SpacerSmall />

          <StyledButton
          disabled={claimingNFT  ?  1 : 0}
            onClick={(e) => {
              e.preventDefault();
              claimNFTs(10);
            }}
          >
            {claimingNFT ? "Busy Minting" : "Adopt a Large Litter: Mint 10"}
          </StyledButton>
          <s.SpacerSmall />

        </s.Container>
      )}


    </s.Screen>
  );
}

export default App;
